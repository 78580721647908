import React from 'react';
import HomeIcon from '@material-ui/icons/WebAsset'; 
import ExtensionIcon from '@material-ui/icons/Extension';

function ChanIcon(props) {
    const {chan} = props

    if (chan && chan.connection == "Extension") {
        return (<ExtensionIcon/>)
    } else {
        return (<HomeIcon/>)
    }
}

export default ChanIcon