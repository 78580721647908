import React from 'react';
import ReactDOM from 'react-dom';
import {
    Route,
    Switch
} from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import App from './App';
//import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <ThemeProvider theme={theme}>
    <BrowserRouter >
    <CssBaseline />
        <Switch>
          <Route path='/' component={App}/>
        </Switch>

    </BrowserRouter>
  </ThemeProvider>
), document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();


