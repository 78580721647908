import React,{useState,useEffect} from 'react';
import ResponsiveDrawer from "./ResponsiveDrawer"
import {chan} from "@nbrowser/nb-chan"
var c = chan("update", "brush")

function App() {
  const [chan,setChan] = useState({"connection":"none"})
  useEffect(() => {
    var promise = new Promise(c.connect)
    promise.then(function(d){
    console.log(d)
    setChan(d)
  })
  }, [])
  return (
    <div>
       <ResponsiveDrawer chan={chan}/>
    </div>
  );
}

export default App;
