import React, {
    useEffect,
    useState
} from "react"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import {
    makeStyles,
    useTheme
} from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PowerSettingsNew';
const cors = "https://vis.nucleome.org/cors"
const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        width: 336,
        height: 200,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 121,
        height: 121,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

function AppCard(props) {
    const {
        data
    } = props
    const classes = useStyles();
    const theme = useTheme();

    const handleOpen = function() {
        window.open(data.url, "", "width=1200,height=750")
    }
    const [version, setVersion] = useState()
    useEffect(function() {
        fetch(cors + "/" + data.url + "/sw.js").then((d) => (d.text()))
            .then((d) => {
                var signal = false
                var h = d.split("\n").slice(0, 4)
                var _v = ""
                h.forEach(function(d0) {
                    var a = d0.match(/VERSION\s*=\s*[\'\"](\S+)[\'\"]/)
                    if (a !== null) {
                        _v = a[1] 
                        signal = true
                    }
                })
                if (!signal) {
                    if ("version" in data) {
                        _v = data.version
                    }
                }
                setVersion(_v)

            }).catch((e) => {
                if ("version" in data) {
                    setVersion(data.version)
                } else {
                    setVersion("")
                }
            })

    }, [data])
    return (
        <Box m={1}>
        <Card className={classes.card}>
        <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="h6" component="h6">
            {data.id}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {version}
          </Typography>
        </CardContent>
         <div className={classes.controls}>
        <IconButton aria-label="open" onClick={handleOpen}>
            <PlayArrowIcon className={classes.playIcon} />
          </IconButton>
         </div>
         </div> 
         <div style={{padding:10}}>
        <CardMedia
          className={classes.cover}
          image={data.icon}
          title={data.id}
        />
         </div>
        </Card>
         </Box>
    )
}
export default AppCard
